import React, { useState, useEffect } from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

import { ChevronDown } from "lucide-react";
import useSourcesStore from "@/stores/sources.store";

interface Props {
  field: any;
  disabled?: boolean;
}

const SelectGroupType: React.FC<Props> = ({ field, disabled }) => {
  // State to keep track of the selected label
  const [selectedLabel, setSelectedLabel] = useState("Select Group Type");
  const networks = useSourcesStore((state) => state.networks);
  console.log(networks);
  const GROUP_TYPES = networks.ENTRY_TYPES.map((network:any) => ({
    label: network.name,
    value: network.symbol,
  }));
  console.log('Group Types', GROUP_TYPES);

  // Update label when field value changes
  useEffect(() => {
    const selectedType = GROUP_TYPES.find((type:any) => type.value === field.value);
    setSelectedLabel(selectedType ? selectedType.label : "Select Group Type");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value]);

  return (
    <div className="flex flex-col">
      <DropdownMenu>
        <DropdownMenuTrigger asChild disabled={disabled}>
          <button className="px-4 py-2 bg-white border border-gray-300 rounded-md shadow-sm text-left text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 flex justify-between items-center">
            {selectedLabel}
            <ChevronDown className="w-5 h-5 text-gray-400" />
          </button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          {GROUP_TYPES.map((type:any) => (
            <DropdownMenuItem
              key={type.value}
              onSelect={() => {
                field.onChange(type.value);
                setSelectedLabel(type.label);
              }}
              className="hover:bg-gray-100 text-gray-700"
            >
              {type.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default SelectGroupType;
