import { BLOCKCHAIN_NETWORKS, EXCHANGE_NETWORKS } from "@/config/general";

import { useEffect } from "react";

import DataTable from "@/components/table/DataTable";
import useAuthStore from "@/stores/auth.store";
import useSourcesStore from "@/stores/sources.store";
import { Source } from "@/types/sources.type";
import { IconsList } from "@/types/tables.type";

const colsBALA = [
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
  {
    id: "entry.asset",
    name: "Asset",
    width: 150,
  },
];

const colsEXCH = [
  {
    id: "entry.network",
    name: "Exchange",
    width: 200,
  },
  {
    id: "entry.secret",
    name: "Secret",
    width: 300,
  },
  {
    id: "entry.key",
    name: "Key",
    width: 300,
  },
];

const colsPUBK = [
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
  {
    id: "entryType",
    name: "Address Type",
    width: 200,
  },
  {
    id: "entry.keys.0",
    name: "xPub Key",
    width: 300,
  },
  {
    id: "entry.keys.length",
    name: "Count",
    width: 150,
  },
];

const colsRISK = [
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
];

const colsSIGN = [
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
  {
    id: "entry.signature",
    name: "Signature",
    width: 350,
  },
  {
    id: "entry.pubkey",
    name: "Public Key",
    width: 300,
  },
];

const colsMap: { [key: string]: { id: string; name: string }[] } = {
  BALA: colsBALA,
  EXCH: colsEXCH,
  PUBK: colsPUBK,
  RISK: colsRISK,
  SIGN: colsSIGN,
};

function RecentSourceEntries() {
  const sourceGroupId = "9961945c-71a5-4d50-af2a-7dc06aee9e16";
  const user = useAuthStore((state) => state.user);
  const sourceEntries: Source[] = useSourcesStore((state) => state.sources);
  const [loading, clearSourceEntries] = useSourcesStore((state) => [
    state.loading,
    state.clearSourceEntries,
  ]);

  const setSelectedSourceGroup = useSourcesStore(
    (state) => state.setSelectedSourceGroup
  );
  useEffect(() => {
    setSelectedSourceGroup(sourceGroupId || "");
    return () => {
      clearSourceEntries();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceGroupId]);

  const [sourceGroup] = useSourcesStore((state) => [
    state.sourceGroups.find((sg) => sg.sourceGroupId === sourceGroupId),
    state.fetchAllSourceGroups,
    state.fetchSourceEntriesForSelectedGroup,
  ]);

  if (!sourceGroup) return null;

  if (!user) {
    return null;
  }
  if (!sourceGroupId) {
    return null;
  }

  const cols = colsMap[sourceGroup.groupType];

  const cellIcons: IconsList[] = (
    sourceGroup.groupType === "EXCH" ? EXCHANGE_NETWORKS : BLOCKCHAIN_NETWORKS
  ).map((network) => ({
    value: network.value,
    img: network.img,
  }));

  return (
    <div className="">
      <DataTable
        columns={cols}
        columnIcons={[
          { field: "entry.network", icons: cellIcons },
          { field: "entry.asset", icons: cellIcons },
        ]}
        data={sourceEntries}
        loading={loading}
        actionButtons={[]}
        rowActions={[]}
      />
    </div>
  );
}

export default RecentSourceEntries;
