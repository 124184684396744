import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
} from "@/components/ui/command";
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import {
  CREATE_WORKSPACE_FORM_BUTTON,
  CREATE_WORKSPACE_FORM_DESCRIPTION,
  CREATE_WORKSPACE_FORM_HEADER,
} from "@/config/forms";
import { LOADING_TEXT } from "@/config/general";
import { cn } from "@/lib/utils";
import useWorkspaceStore from "@/stores/workspaces.store";
import {
  CaretSortIcon,
  CheckIcon,
  PlusCircledIcon,
  ReloadIcon,
} from "@radix-ui/react-icons";
import * as React from "react";
import { useState } from "react";
import { WorkspaceSwitcherModal } from "../dialog/WorkspaceModal";
import { CreateWorkspaceForm } from "../forms/CreateWorkspaceForm";

type PopoverTriggerProps = React.ComponentPropsWithoutRef<
  typeof PopoverTrigger
>;

interface WorkspaceSwitcherProps extends PopoverTriggerProps {}

export default function WorkspaceSwitcher({
  className,
}: WorkspaceSwitcherProps) {
  const [open, setOpen] = useState(false);

  const [showNewWorkspaceDialog, setShowNewWorkspaceDialog] = useState(false);

  const [workspaceIdToEdit, setWorkspaceIdToEdit] = useState<string | null>(
    null
  );
  const [workspaces, loadingWorkspace] = useWorkspaceStore((state) => [
    state.workspaces,
    state.loading,
  ]);

  const [setSelectedWorkspace, getSelectedWorkspace] = useWorkspaceStore(
    (state) => [state.setSelectedWorkspace, state.getSelectedWorkspace]
  );

  const selectedWorkspace = getSelectedWorkspace();

  const getSelectedWorkspaceString = (): string => {
    if (selectedWorkspace) {
      return selectedWorkspace.workspaceName;
    }

    return "Select a workspace";
  };

  return (
    <WorkspaceSwitcherModal
      isOpen={showNewWorkspaceDialog}
      setIsOpen={setShowNewWorkspaceDialog}
    >
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            disabled={loadingWorkspace}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            aria-label="Select a workspace"
            className={cn(
              "w-[200px] justify-between",
              "bg-white dark:bg-gray-800 text-black dark:text-white dark:border dark:border-gray-700",
              className
            )}
          >
            {loadingWorkspace ? (
              <>
                <Avatar className="mr-2 h-5 w-5">
                  <ReloadIcon className="animate-spin mt-0.5" />
                </Avatar>
                {LOADING_TEXT}
                <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
              </>
            ) : (
              <>
                <Avatar className="mr-2 h-5 w-5">
                  <AvatarImage
                    src={`https://avatar.vercel.sh/${getSelectedWorkspaceString()}.png`}
                    alt={getSelectedWorkspaceString()}
                  />
                  <AvatarFallback>SC</AvatarFallback>
                </Avatar>
                {getSelectedWorkspaceString().substring(0, 15) +
                  (getSelectedWorkspaceString.length > 15 ? "..." : "")}
                <CaretSortIcon className="ml-auto h-4 w-4 shrink-0 opacity-50" />
              </>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[400px] p-0 ml-4 bg-white dark:bg-gray-900/20 dark:border dark:border-gray-700 text-black dark:text-white">
          <Command>
            <CommandList>
              <CommandInput placeholder="Search workspaces..." />
              <CommandEmpty>No workspace found</CommandEmpty>
              <CommandGroup heading={"My Workspaces"}>
                {workspaces
                  .sort((a, b) =>
                    a.workspaceName.localeCompare(b.workspaceName)
                  )
                  .map((workspace, index) => (
                    <CommandItem
                      key={index}
                      onSelect={() => {
                        setSelectedWorkspace(workspace.workspaceId);
                        setWorkspaceIdToEdit(workspace.workspaceId);
                        setOpen(false);
                      }}
                      className="text-sm flex justify-between items-center"
                    >
                      <div className="flex items-center">
                        <Avatar className="mr-2 h-5 w-5">
                          <AvatarImage
                            src={`https://avatar.vercel.sh/${workspace.workspaceId}.png`}
                            alt={workspace.workspaceName}
                            className="grayscale"
                          />
                          <AvatarFallback>SC</AvatarFallback>
                        </Avatar>
                        <p className="text-black dark:text-white">
                          {workspace.workspaceName}
                        </p>
                      </div>
                      <div className="flex items-center">
                        <CheckIcon
                          className={cn(
                            "ml-auto h-4 w-4",
                            selectedWorkspace?.workspaceId ===
                              workspace.workspaceId
                              ? "opacity-100"
                              : "opacity-0"
                          )}
                        />
                      </div>
                    </CommandItem>
                  ))}
              </CommandGroup>
            </CommandList>
            <CommandSeparator />
            <CommandList>
              <CommandGroup>
                <DialogTrigger asChild>
                  <CommandItem
                    onSelect={() => {
                      setOpen(false);
                      setShowNewWorkspaceDialog(true);
                    }}
                  >
                    <PlusCircledIcon className="mr-2 h-5 w-5" />
                    {CREATE_WORKSPACE_FORM_BUTTON}
                  </CommandItem>
                </DialogTrigger>
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
      <DialogContent className="bg-white dark:bg-gray-700 text-black dark:text-white">
        <DialogHeader>
          <DialogTitle>{CREATE_WORKSPACE_FORM_HEADER}</DialogTitle>
          <DialogDescription>
            {CREATE_WORKSPACE_FORM_DESCRIPTION}
          </DialogDescription>
        </DialogHeader>
        <Separator />
        <CreateWorkspaceForm
          setModal={setShowNewWorkspaceDialog}
          workspaceId={workspaceIdToEdit || undefined}
        />
      </DialogContent>
    </WorkspaceSwitcherModal>
  );
}
