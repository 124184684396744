import { FC } from "react";
import { SourceForm } from "../forms/SourceForm";
import { GeneralModal } from "./GeneralModal";

interface SourceModalProps {
  sourceId?: string | null;
  isEditDialogOpen: boolean;
  setIsEditDialogOpen: (value: boolean) => void;
}

export const SourceModal: FC<SourceModalProps> = ({
  sourceId,
  isEditDialogOpen,
  setIsEditDialogOpen,
}) => {
  const id = !!sourceId ? sourceId : null;

  return (
    <GeneralModal
      title={sourceId ? "Rename Source" : "Create Source"}
      description={
        sourceId
          ? "You can edit the Source details here"
          : "You can create a new Source here"
      }
      isEditDialogOpen={isEditDialogOpen}
      setIsEditDialogOpen={setIsEditDialogOpen}
    >
      <SourceForm sourceId={id} onClose={() => setIsEditDialogOpen(false)} />
    </GeneralModal>
  );
};
