import {
    getAllWorkspaces,
    getWorkspaceDashboard,
} from "../services/workspaceService";

import { create } from "zustand";
import useReportsStore from "./reports.store";
import useSourcesStore from "./sources.store";

export interface WorkspaceDashboard {
  portfolioValue: number;
  tokenAllocation: Record<string, string>;
  totalReports: number;
  totalFailedReports: number;
  recentTransactions: any[];
}

export interface Workspace {
  access: string;
  currency: string;
  orgId: string;
  userId: string;
  workspaceId: string;
  workspaceName: string;
  updated: number;
}

type WorkspaceState = {
  workspaces: Workspace[];
  selectedWorkspaceId: string;
  selectedWorkspaceDashboard: WorkspaceDashboard | null;
  loading: boolean;
  setSelectedWorkspace: (workspaceId: string) => Promise<void>;
  fetchWorkspaces: () => Promise<void>;
  getSelectedWorkspace: () => Workspace | undefined;
};

const useWorkspaceStore = create<WorkspaceState>((set, get) => ({
  workspaces: [],
  selectedWorkspaceId: "",
  selectedWorkspaceDashboard: null,
  loading: false,
  setSelectedWorkspace: async (workspaceId: string) => {
    try {
      set({ loading: true, selectedWorkspaceId: workspaceId });
      const startDate = "2022-01-01";
      const endDate = "2022-01-31";
      const dashboardResponse = await getWorkspaceDashboard(
        workspaceId,
        startDate,
        endDate
      );

      console.log("Dashboard Data :: : : : :", dashboardResponse)

      set({
        selectedWorkspaceDashboard: dashboardResponse.data,
        loading: false,
      });
      useSourcesStore.getState().fetchAllSourceGroups();
      useReportsStore.getState().fetchAllReportGroups();
      set({ loading: false });
    } catch {
      set({ loading: false });
    }
  },
  fetchWorkspaces: async () => {
    set({ loading: true });
    const response = await getAllWorkspaces();
    set({
      workspaces: response.data.data,
      loading: false,
    });
    if (get().selectedWorkspaceId === "" && get().workspaces.length > 0) {
      get().setSelectedWorkspace(get().workspaces[0].workspaceId);
    }
  },
  getSelectedWorkspace: () => {
    const selectedProfileId = get().selectedWorkspaceId;
    if (!selectedProfileId) {
      return;
    }
    return get().workspaces.find(
      (workspace) => workspace.workspaceId === selectedProfileId
    );
  },
}));

export default useWorkspaceStore;
