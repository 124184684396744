import { FileClock, Fingerprint, FolderOpen } from "lucide-react";

import { animated, config, useSpring } from "@react-spring/web";
import { FC } from "react";

interface ServiceCardProps {
  icon: React.ReactNode;
  title: string;
  details: string;
  delay: number; // Add delay as a prop
}

const ServiceCard: FC<ServiceCardProps> = ({ icon, title, details, delay }) => {
  const cardAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(50px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: { tension: 170, friction: 26 },
    delay,
  });

  return (
    <animated.div
      style={cardAnimation}
      className="flex items-stretch w-full px-4 md:w-1/2 lg:w-1/3 "
    >
      <div className="mb-9 h-100 rounded-[20px] bg-white p-7 shadow-2 hover:shadow-lg dark:bg-sky-900 md:px-4 xl:px-7">
        <div className="mb-4 flex h-[50px] w-[50px] items-center justify-center rounded-2xl bg-primary">
          {icon}
        </div>
        <h4 className="mb-[14px] text-2xl font-semibold text-dark dark:text-white">
          {title}
        </h4>
        <p className="text-body-color dark:text-gray-100">{details}</p>
      </div>
    </animated.div>
  );
};

export const FeatureSection = () => {
  const titleAnimation = useSpring({
    from: { opacity: 0, transform: "translateY(-20px)" },
    to: { opacity: 1, transform: "translateY(0)" },
    config: config.wobbly,
  });

  const subtitleAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: config.molasses,
    delay: 300,
  });

  return (
    <section
      id="services"
      className="pt-20 dark:bg-sky-900/[.75] lg:pb-[90px] lg:pt-[120px] bg-primary/10"
    >
      <div className="container mx-auto">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mx-auto mb-12 max-w-[510px] text-center lg:mb-20">
              <animated.h2
                style={titleAnimation}
                className="mb-3 text-3xl font-bold leading-[1.2] text-dark dark:text-white sm:text-4xl md:text-[40px]"
              >
                Easy, accurate crypto tax reports
              </animated.h2>
              <animated.p
                style={subtitleAnimation}
                className="text-base text-body-color dark:text-dark-6"
              >
                Our Robust User-Friendly Platform will help you manage all your
                clients' taxes with confidence. <br />
              </animated.p>
            </div>
          </div>
        </div>

        <div className="-mx-4 flex flex-wrap">
          <ServiceCard
            title="Comprehensive Portfolio Accounting"
            details="Integrating data from diverse sources, gain insights like never before on transactions, values, and balances."
            icon={<FolderOpen size={36} color="#ffffff" strokeWidth={1.75} />}
            delay={0}
          />
          <ServiceCard
            title="Historic Snapshot Analysis"
            details="Precise computations let you revisit any moment in your portfolio's history with confidence."
            icon={<FileClock size={36} color="#ffffff" strokeWidth={1.75} />}
            delay={200}
          />
          <ServiceCard
            title="Digital Signature Verification"
            details="Our platform seamlessly verifies digital signatures across any blockchain, and can even derive addresses from extended keys, ensuring authenticity at every step."
            icon={<Fingerprint size={36} color="#ffffff" strokeWidth={1.75} />}
            delay={400}
          />
        </div>
      </div>
    </section>
  );
};
