import * as z from "zod";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { createSourceGroup, editSourceGroup } from "@/services/sourceService";
import { FC, useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { LOADING_TEXT } from "@/config/general";
import useAuthStore from "@/stores/auth.store";
import useSourcesStore from "@/stores/sources.store";
import useWorkspaceStore from "@/stores/workspaces.store";
import { SourceGroup } from "@/types/sources.type";
import { SourceGroupSchema } from "@/zod/source.zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import SelectGroupType from "../ledgernalysis/SelectGroupType";
import { useToast } from "../ui/use-toast";

interface SourceFormProps {
  sourceId: string | null;
  onClose?: () => void;
}

export const SourceForm: FC<SourceFormProps> = ({ sourceId, onClose }) => {
  const { toast } = useToast();

  const [sourceOnFile, fetchAllSourceGroups] = useSourcesStore((state) => [
    state.sourceGroups.find((source) => source.sourceGroupId === sourceId),
    state.fetchAllSourceGroups,
  ]);
  const workspaceId = useWorkspaceStore((state) => state.selectedWorkspaceId);

  const [source, setSource] = useState<SourceGroup>(
    sourceOnFile as SourceGroup
  );
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const email = useAuthStore((state) => state.user?.email);

  const sourceForm = useForm<SourceFormType>({
    resolver: zodResolver(SourceGroupSchema),
    defaultValues: {
      sourceName: "",
      groupType: "BALA",
    },
  });

  useEffect(() => {
    if (sourceId && source) {
      sourceForm.reset(source || {});
    }
  }, [sourceId, source, sourceForm]);

  useEffect(() => {
    if (sourceOnFile) {
      setSource(sourceOnFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  type SourceFormType = z.infer<typeof SourceGroupSchema>;

  const onSubmit = async (data: SourceFormType) => {
    try {
      setLoading(true);
      if (!email) {
        return;
      }

      let response: any;
      if (sourceId) {
        // sourceGroupId = sourceId;
        response = await editSourceGroup(sourceId, data.sourceName);
        console.log("Updated source", response);
      } else {
        response = await createSourceGroup(
          workspaceId,
          data.sourceName,
          data.groupType
        );
      }

      toast({
        title: `Source ${sourceId ? "Updated" : "Created"}`,
        description: sourceId
          ? "Edit existing entries of your source."
          : "You can now add entries to your source.",
      });
      fetchAllSourceGroups();
      // TODO: Dismiss overlay
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
      if (onClose) {
        onClose();
      }
    }
  };
  return (
    <Form {...sourceForm}>
      <div className="mt-4">
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
      <form onSubmit={sourceForm.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={sourceForm.control}
          name="groupType"
          render={({ field }) => (
            <FormItem
              className={
                source?.count > 0 ? "opacity-50 cursor-not-allowed" : ""
              }
            >
              <FormLabel>
                <div>
                  Source Type
                  <p className="text-xs font-normal py-2">
                    *Sources can only contain entries of a single type.
                  </p>
                </div>
              </FormLabel>
              <FormControl>
                <SelectGroupType disabled={source?.count > 0} field={field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={sourceForm.control}
          name="sourceName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Source Name</FormLabel>
              <FormControl>
                <Input placeholder="Enter source name" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        {loading ? (
          <Button disabled className="w-full" size="lg">
            <span className="loading-icon" /> {/* Add loading icon */}
            {LOADING_TEXT}
          </Button>
        ) : (
          <Button type="submit" className="w-full" size="lg">
            {sourceId ? "Update Source" : "Create Source"}
          </Button>
        )}
      </form>
    </Form>
  );
};
