import {
  Activity,
  ArrowDown,
  ArrowUp,
  ArrowUpRight,
  CreditCard,
  DollarSign,
  Users,
} from "lucide-react";

import AnimatedCard from "@/components/cards/AnimatedCard";
import { AreaChartCard } from "@/components/cards/AreaChartCard";
import { DashboardGuideCard } from "@/components/cards/DashboardGuideCard";
import DashboardWorkspaceCard from "@/components/cards/DashboardWorkspaceCard";
import { PieChartCard } from "@/components/cards/PieChartCard";
import RecentSourceEntries from "@/components/cards/RecentSourceEntries";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import useWorkspaceStore from "@/stores/workspaces.store";
import { animated, useSpring } from "@react-spring/web";

export default function Dashboard() {
  const [workspaces, getSelectedWorkspace, loadingProfile] = useWorkspaceStore(
    (state) => [state.workspaces, state.getSelectedWorkspace, state.loading]
  );

  const selectedWorkspace = getSelectedWorkspace();
  console.log("SELECTED WorkSpace :::    ", selectedWorkspace);

  const cardSpring = useSpring({
    from: { opacity: 0, transform: "scale(0.95) translateY(20px)" },
    to: { opacity: 1, transform: "scale(1) translateY(0px)" },
    config: { tension: 200, friction: 30 },
    delay: 100,
  });
  return (
    <div className="flex min-h-screen w-full flex-col">
      <main className="flex flex-1 flex-col gap-4 p-4 md:gap-8 md:p-8 max-w-screen-xl mx-auto">
        <div className="grid col-span-2 gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3">
          <AnimatedCard>
            <DashboardWorkspaceCard
              selectedWorkspace={selectedWorkspace}
              workspaces={workspaces}
              loadingProfile={loadingProfile}
            />
          </AnimatedCard>
        </div>
        <div className="grid col-span-4">
          <animated.div style={cardSpring}>
            <DashboardGuideCard />
          </animated.div>
        </div>

        <div className="grid gap-4 md:grid-cols-2 md:gap-8 lg:grid-cols-4">
          <animated.div style={cardSpring}>
            <Card x-chunk="dashboard-01-chunk-0">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Total Portfolio
                </CardTitle>
                <DollarSign className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">$45,231.89</div>
                <p className="text-xs text-muted-foreground">
                  +20.1% from last month
                </p>
              </CardContent>
            </Card>
          </animated.div>

          <animated.div style={cardSpring}>
            <Card x-chunk="dashboard-01-chunk-1">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Report Entries
                </CardTitle>
                <Users className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">+2350</div>
                <p className="text-xs text-muted-foreground">
                  +180.1% from last month
                </p>
              </CardContent>
            </Card>
          </animated.div>

          <animated.div style={cardSpring}>
            <Card x-chunk="dashboard-01-chunk-2">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">Assets</CardTitle>
                <CreditCard className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">+12,234</div>
                <p className="text-xs text-muted-foreground">
                  +19% from last month
                </p>
              </CardContent>
            </Card>
          </animated.div>

          <animated.div style={cardSpring}>
            <Card x-chunk="dashboard-01-chunk-3">
              <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                <CardTitle className="text-sm font-medium">
                  Assets Today
                </CardTitle>
                <Activity className="h-4 w-4 text-muted-foreground" />
              </CardHeader>
              <CardContent>
                <div className="text-2xl font-bold">+573</div>
                <p className="text-xs text-muted-foreground">
                  +201 since last hour
                </p>
              </CardContent>
            </Card>
          </animated.div>
        </div>
        <div className="grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3">
          <animated.div style={cardSpring} className="xl:col-span-2">
            <AreaChartCard />
          </animated.div>
          <animated.div style={cardSpring}>
            <PieChartCard />
          </animated.div>
        </div>
        <div className="grid gap-4 md:gap-8 lg:grid-cols-2 xl:grid-cols-3">
          <animated.div style={cardSpring} className="xl:col-span-2">
            <Card x-chunk="dashboard-01-chunk-6">
              <CardHeader className="flex flex-row items-center">
                <div className="grid gap-2">
                  <CardTitle>Transactions</CardTitle>
                  <CardDescription>Recent transactions.</CardDescription>
                </div>
                <Button asChild size="sm" className="ml-auto gap-1">
                  <a href="#">
                    View All
                    <ArrowUpRight className="h-4 w-4" />
                  </a>
                </Button>
              </CardHeader>
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>Asset</TableHead>
                      <TableHead className="hidden xl:table-column">
                        Transaction ID
                      </TableHead>
                      <TableHead className="hidden xl:table-column">
                        Type
                      </TableHead>
                      <TableHead className="hidden xl:table-column">
                        Date
                      </TableHead>
                      <TableHead className="text-right">Amount</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <div className="font-semibold text-lg">BTC</div>
                        <div className="text-sm text-muted-foreground">
                          f47ac10b-58cc-4372-a567-0e02b2c3d479
                        </div>
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        <ArrowDown className="text-red-500" /> Inflow
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        2024-08-20
                      </TableCell>
                      <TableCell className="text-right">0.5 BTC</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div className="font-semibold text-lg">ETH</div>
                        <div className="text-sm text-muted-foreground">
                          b47ac10b-58cc-4372-a567-0e02b2c3d470
                        </div>
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        <ArrowUp className="text-green-500" /> Outflow
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        2024-08-20
                      </TableCell>
                      <TableCell className="text-right">2.5 ETH</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div className="font-semibold text-lg">LTC</div>
                        <div className="text-sm text-muted-foreground">
                          c47ac10b-58cc-4372-a567-0e02b2c3d481
                        </div>
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        <ArrowDown className="text-red-500" /> Inflow
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        2024-08-20
                      </TableCell>
                      <TableCell className="text-right">1.2 LTC</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div className="font-semibold text-lg">BTC</div>
                        <div className="text-sm text-muted-foreground">
                          d47ac10b-58cc-4372-a567-0e02b2c3d492
                        </div>
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        <ArrowUp className="text-green-500" /> Outflow
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        2024-08-20
                      </TableCell>
                      <TableCell className="text-right">0.3 BTC</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div className="font-semibold text-lg">ETH</div>
                        <div className="text-sm text-muted-foreground">
                          e47ac10b-58cc-4372-a567-0e02b2c3d4a3
                        </div>
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        <ArrowDown className="text-red-500" /> Inflow
                      </TableCell>
                      <TableCell className="hidden xl:table-column">
                        2024-08-20
                      </TableCell>
                      <TableCell className="text-right">3.0 ETH</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>{" "}
              </CardContent>
            </Card>
          </animated.div>

          <animated.div
            style={cardSpring}
            className="flex justify-center items-center"
          >
            <Card x-chunk="dashboard-01-chunk-7">
              <CardHeader>
                <CardTitle>Recent Source Entries</CardTitle>
              </CardHeader>
              <CardContent className="grid gap-8">
                <RecentSourceEntries />
              </CardContent>
            </Card>
          </animated.div>
        </div>
      </main>
    </div>
  );
}
