import {
  safelyAccessFirstAssetArray,
  safelyAccessFlatArray,
} from "@/lib/utils";
import {
  ApiTransactionsPayload,
  AssetTransaction,
  ChartDataItem,
  DownloadResponse,
  Report,
  ReportGroup,
  TransactionData,
} from "@/types/report.type";
import { create } from "zustand";
import {
  deleteReportGroup,
  downloadReportGroup,
  downloadTx,
  getAllReportGroups,
  getAllReports,
  getGraphData,
  getTransactions,
} from "../services/reportService";
import useWorkspaceStore from "./workspaces.store";

type ReportsState = {
  loading: boolean;
  reportGroups: ReportGroup[];
  reports: Report[];
  selectedReportGroup: string;
  fetchAllReportGroups: () => Promise<void>;
  fetchAllReportEntriesForSelectedGroup: () => Promise<void>;
  setSelectedReportGroup: (reportGroupId: string) => void;
  // createReportGroup: (reportName: string, groupType: string) => Promise<void>;
  deleteReportGroup: (reportGroupId: string) => Promise<void>;
  getTransactions: (
    reportId: string,
    limit: number,
    page: number
  ) => Promise<ApiTransactionsPayload<TransactionData>>;
  getGraphData: (reportId: string) => Promise<ChartDataItem[]>;
  downloadTx: (reportId: string) => Promise<DownloadResponse | string>;
  downloadReport: (reportGroupId: string) => Promise<string>;
};

const useReportsStore = create<ReportsState>((set, get) => ({
  loading: false,
  reportGroups: [],
  reports: [],
  selectedReportGroup: "",
  fetchAllReportGroups: async () => {
    set({ loading: true });
    const workspace: any = useWorkspaceStore.getState().getSelectedWorkspace();
    const workspaceId = workspace?.workspaceId || "";
    const response = await getAllReportGroups(workspaceId);
    set({
      reportGroups: response.data.data,
      loading: false,
    });
  },
  fetchAllReportEntriesForSelectedGroup: async () => {
    set({ loading: true });
    const selectedReportGroup = get().selectedReportGroup;
    if (!selectedReportGroup) return;
    const response = await getAllReports(selectedReportGroup);
    set({
      reports: response.data.data,
      loading: false,
    });
  },
  setSelectedReportGroup: (reportGroupId: string) => {
    set({
      selectedReportGroup: reportGroupId,
    });
    get().fetchAllReportEntriesForSelectedGroup();
  },
  // createReportGroup: async (reportName: string, groupType: string) => {
  //   set({ loading: true });
  //   const user: any = useAuthStore.getState().user;
  //   const workspaceId = user?.workspaceId || "";
  //   await createReportWithSourceGroups(workspaceId, reportName, groupType);
  //   set({ loading: false });
  //   get().fetchAllReportGroups();
  // },
  deleteReportGroup: async (reportGroupId: string) => {
    set({ loading: true });
    await deleteReportGroup(reportGroupId);
    set({ loading: false });
    get().fetchAllReportGroups();
  },
  getTransactions: async (reportId: string, limit: number, page: number) => {
    set({ loading: true });

    console.log("Report ID", reportId);
    const response = await getTransactions(reportId, limit, page);
    const flatTransactions = safelyAccessFlatArray<TransactionData>(
      response.data.data
    );
    set({ loading: false });
    return {
      page: page,
      limit: limit,
      pages: response.data.data[0].pages,
      max: response.data.data[0].max,
      data: flatTransactions.sort((a, b) => b.blockheight - a.blockheight),
    };
  },
  getGraphData: async (reportId: string) => {
    set({ loading: true });
    const response: any = await getGraphData(reportId);
    const assetTransactions =
      safelyAccessFirstAssetArray<AssetTransaction>(response);
    set({ loading: false });
    return assetTransactions;
  },
  downloadTx: async (reportId: string) => {
    const response = await downloadTx(reportId);
    return response.data;
  },
  downloadReport: async () => {
    const reportGroupId = get().selectedReportGroup;
    const response = await downloadReportGroup(reportGroupId);
    return response.data.downloadLink;
  },
}));

export default useReportsStore;
