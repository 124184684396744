import { Source, SourceGroup } from "@/types/sources.type";
import {
  downloadTemplate,
  getAllSourceGroups,
  getAllSources,
  uploadSource,
} from "../services/sourceService";

import { create } from "zustand";
import { getNetworks } from "@/services/generalService";
import { networksResponse } from "@/config/networks";
import useWorkspaceStore from "./workspaces.store";

type SourcesState = {
  loading: boolean;
  sourceGroups: SourceGroup[];
  sources: Source[];
  clearSourceEntries: () => void;
  networks: any;
  setNetworks: (networks: any[]) => void;
  selectedSourceGroupId: string;
  fetchAllSourceGroups: () => Promise<void>;
  setSelectedSourceGroup: (sourceId: string) => void;
  fetchSourceEntriesForSelectedGroup: () => Promise<void>;
  uploadSource: (file: string, sourceGroupId: string) => Promise<void>;
  downloadTemplate: (type: string) => Promise<string>;
};

const useSourcesStore = create<SourcesState>((set, get) => ({
  loading: false,
  sourceGroups: [],
  sources: [],
  clearSourceEntries: () => set({ sources: [] }),
  networks: [],
  setNetworks: (networks) => set({ networks }),
  selectedSourceGroupId: "",
  fetchAllSourceGroups: async () => {
    set({ loading: true });
    const workspace: any = useWorkspaceStore.getState().getSelectedWorkspace();
    const workspaceId = workspace?.workspaceId || "";
    const response = await getAllSourceGroups(workspaceId);
    console.log("Source Groups loaded:", response);
    set({
      // error: response.data.error,
      // message: response.data.message,
      sourceGroups: response.data.data,
      loading: false,
    });
  },
  setSelectedSourceGroup: (sourceId) => {
    console.log("Selected source group:", sourceId);
    set({
      selectedSourceGroupId: sourceId,
    });
    get().fetchSourceEntriesForSelectedGroup();
  },
  uploadSource: async (file, sourceGroupId) => {
    console.log("Uploading source:", file, sourceGroupId);
    await uploadSource(file, sourceGroupId);
    await get().fetchAllSourceGroups();
    get().setSelectedSourceGroup(sourceGroupId);
    await get().fetchSourceEntriesForSelectedGroup();
  },
  fetchSourceEntriesForSelectedGroup: async () => {
    set({ loading: true });
    const selectedSourceGroupId = get().selectedSourceGroupId;
    console.log("Selected source group:", selectedSourceGroupId);
    if (!selectedSourceGroupId) return;
    const response = await getAllSources(selectedSourceGroupId);
    console.log("Source Entries response:", response);
    set({
      sources: response.data.data,
      loading: false,
    });
  },
  downloadTemplate: async (type) => {
    const response = await downloadTemplate(type);
    return response.data;
  },
}));

const fetchNetworks = async () => {
  try {
    const response = await getNetworks();
    useSourcesStore.getState().setNetworks(response.data.data);
  } catch (error) {
    useSourcesStore.getState().setNetworks(networksResponse.data as any);
  }
};

fetchNetworks();

export default useSourcesStore;
