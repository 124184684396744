import DashboardSideMenu from "@/components/layout/DashboardSideMenu";
import AccountManagement from "@/components/ledgernalysis/AccountManagement";
import ErrorFallback from "@/components/misc/ErrorFallback";
import Auth from "@/pages/Auth";
import Dashboard from "@/pages/Dashboard";
import DashboardGuidePage from "@/pages/DashboardGuide";
import Home from "@/pages/Home";
import ReportEntries from "@/pages/ReportEntries";
import ReportEntrySingle from "@/pages/ReportEntry";
import Reports from "@/pages/Reports";
import RunReport from "@/pages/RunReport";
import RunSocket from "@/pages/RunSocket";
import SourceEntries from "@/pages/SourceEntries";
import Sources from "@/pages/Sources";
import { Navigate } from "react-router-dom";

const routes = [
  {
    path: "/",
    element: <DashboardSideMenu />,
    errorElement: <ErrorFallback message="An error occurred in the Dashboard." />,
    children: [
      { path: "/dashboard", element: <Dashboard />, errorElement: <ErrorFallback /> },
      { path: "/sources", element: <Sources />, errorElement: <ErrorFallback /> },
      { path: "/sources/:sourceGroupId", element: <SourceEntries />, errorElement: <ErrorFallback /> },
      { path: "/reports", element: <Reports />, errorElement: <ErrorFallback /> },
      { path: "/reports/:reportGroupId", element: <ReportEntries />, errorElement: <ErrorFallback /> },
      {
        path: "/reports/:reportGroupId/:reportId",
        element: <ReportEntrySingle />,
        errorElement: <ErrorFallback />,
      },
      { path: "/account", element: <AccountManagement />, errorElement: <ErrorFallback /> },
      { path: "/reports/run/:source?", element: <RunReport />, errorElement: <ErrorFallback /> },
      { path: "/socket/run", element: <RunSocket />, errorElement: <ErrorFallback /> },
      { path: "/dashboard/guide", element: <DashboardGuidePage />, errorElement: <ErrorFallback /> },
    ],
  },
  {
    path: "/auth",
    element: <Auth />,
    errorElement: <ErrorFallback message="An error occurred during authentication." />,
  },
  {
    path: "/login",
    element: <Auth />,
    errorElement: <ErrorFallback message="An error occurred during login." />,
  },
  {
    path: "/home",
    element: <Home />,
    errorElement: <ErrorFallback message="An error occurred on the Home page." />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
    errorElement: <ErrorFallback />,
  },
];

export default routes;
