import { AssetData } from "@/types/report.type";
import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const formatXAxis = (tickItem: number, index: number, data: AssetData[]) => {
  const currentDate = new Date(tickItem);
  const previousDate = index > 0 ? new Date(data[index - 1].datetime) : null;

  if (
    previousDate &&
    currentDate.getMonth() === previousDate.getMonth() &&
    currentDate.getFullYear() === previousDate.getFullYear()
  ) {
    // If the same month of the same year, include the day
    return `${currentDate.getDate()} ${currentDate.toLocaleString("en-US", { month: "short" }).toUpperCase()}`;
  } else {
    // Otherwise, just return the month and year
    return currentDate
      .toLocaleString("en-US", { month: "short", year: "numeric" })
      .toUpperCase();
  }
};

const getYAxisDomain = (data: AssetData[]) => {
  //@ts-ignore-error
  const min = Math.min(...data.map((item) => Number(item.after)));
  //@ts-ignore-error
  //TODO: Fix the type mismatch
  const max = Math.max(...data.map((item) => Number(item.after)));
  return [min * 0.9, max * 1.1]; // Add some padding
};

interface AssetLineChartProps {
  data: AssetData[];
}

const AssetAreaChart: React.FC<AssetLineChartProps> = ({ data }) => {
  const assetName = data.length > 0 ? data[0].asset : "Asset";
  const chartHeight = Math.max(Math.min(data.length * 10, 500), 300); // Adjust height

  return (
    <ResponsiveContainer width="100%" height={chartHeight}>
      <AreaChart data={data}>
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis
          dataKey="datetime"
          tickFormatter={(tickItem, index) =>
            formatXAxis(tickItem, index, data)
          }
          tick={{ fontSize: 12 }}
          interval={Math.floor(data.length / 6)} // Adjust interval to spread out labels
          padding={{ left: 20, right: 20 }} // Add padding to prevent cutting off
        />
        <YAxis
          dataKey="after"
          domain={getYAxisDomain(data)} // Dynamic range
          tickFormatter={(value) => parseFloat(value).toFixed(6)}
          tick={{ fontSize: 12 }}
        />
        <defs>
          <linearGradient id="fillTime" x1="0" y1="0" x2="0" y2="1">
            <stop offset="20%" stopColor="#1d4ed8" stopOpacity={1} />
            <stop offset="100%" stopColor="#1d4ed8" stopOpacity={0.4} />
          </linearGradient>
        </defs>
        <Area
          dataKey="after"
          type="natural"
          name={assetName}
          fill="url(#fillTime)"
          fillOpacity={0.4}
          stroke="#1d4ed8"
        />
        <Tooltip
          labelFormatter={(label) =>
            new Date(label).toLocaleString("en-US", {
              month: "short",
              year: "numeric",
            })
          }
          formatter={(value: string) => parseFloat(value).toFixed(6)}
        />
        <Legend />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default AssetAreaChart;
