import { Eye, Plus, Trash } from "lucide-react";

import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import { ConfirmDialog } from "@/components/dialog/ConfirmDialog";
import DataTable from "@/components/table/DataTable";
import { Progress } from "@/components/ui/progress";
import { useToast } from "@/components/ui/use-toast";
import { getNameBySymbol } from "@/lib/utils";
import useReportsStore from "@/stores/reports.store";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const formatStatus = (status: string, message?: string) => {
  const statusMap: Record<
    string,
    { label: string; textColor: string; bgColor: string }
  > = {
    S: {
      label: "Success",
      textColor: "text-green-700",
      bgColor: "bg-green-200",
    },
    F: { label: "Failed", textColor: "text-red-700", bgColor: "bg-red-200" },
    P: {
      label: "Pending",
      textColor: "text-orange-700",
      bgColor: "bg-orange-200",
    },
    R: { label: "Running", textColor: "text-blue-700", bgColor: "bg-blue-200" },
  };

  const statusInfo = statusMap[status];
  if (!statusInfo) return null;

  return (
    <div className="flex items-center">
      <span
        className={`${statusInfo.textColor} ${statusInfo.bgColor} px-2 py-1 rounded-full text-xs`}
      >
        {statusInfo.label}
      </span>
      {message && (
        <div className={`mx-4 py-1 px-1 ${statusInfo.textColor} text-xs`}>
          {message}
        </div>
      )}
    </div>
  );
};
function Reports() {
  const navigate = useNavigate();
  const [deleteReportDialogOpen, setDeleteReportDialogOpen] = useState(false);
  const [deleteReportGroupId, setDeleteReportGroupId] = useState("");
  const deleteReportGroup = useReportsStore((state) => state.deleteReportGroup);

  const { toast } = useToast();

  const cols = [
    {
      id: "reportName",
      name: "Name",
      width: 300,
    },
    {
      id: "groupType",
      name: "Type",
      width: 150,
      cell: (value: string) => getNameBySymbol(value),
    },
    {
      id: "progress",
      name: "Progress",
      cell: (value: any) => (
        <div className="w-1/2 text-center">
          <Progress value={value} />
          {value} %
        </div>
      ),
      width: 160,
    },
    {
      id: "status",
      name: "Status",
      cell: (value: any) => formatStatus(value),
      width: 150,
    },
  ];
  const reportGroups = useReportsStore((state) => state.reportGroups);
  const loading = useReportsStore((state) => state.loading);
  return (
    <DashboardMainCard header={"Reports"} description={"View and run reports"}>
      <ConfirmDialog
        title="Delete Report"
        description="Are you sure you want to delete this report?"
        open={deleteReportDialogOpen}
        setOpen={setDeleteReportDialogOpen}
        severity="error"
        onConfirm={async () => {
          await deleteReportGroup(deleteReportGroupId);
          toast({
            title: "Report deleted",
            description: "Report was deleted successfully",
          });
        }}
      />
      <DataTable
        columns={cols}
        data={reportGroups}
        loading={loading}
        globalFilter={{
          placeholder: "Filter Report Names...",
          accessorKey: "reportName",
        }}
        actionButtons={[
          {
            onClick: () => navigate("/reports/run"),
            text: "New report",
            icon: Plus,
          },
        ]}
        rowActions={[
          {
            text: "View",
            onClick: (row) => {
              row.status !== "P" && navigate(`/reports/${row.reportGroupId}`);
            },
            icon: Eye,
            disabled: (row) => row.status === "P",
          },
          {
            text: "Delete",
            onClick: (row) => {
              row.status !== "P" && setDeleteReportGroupId(row.reportGroupId);
              row.status !== "P" && setDeleteReportDialogOpen(true);
            },
            icon: Trash,
            disabled: (row) => row.status === "P",
            variant: "danger",
          },
        ]}
      />
    </DashboardMainCard>
  );
}

export default Reports;
