import apiService from "./apiService";

export const getAllReportGroups = async (workspaceId: string) => {
  return apiService.get(`/getReportGroups`, {
    params: { workspaceId, limit: 100, page: 1 },
  });
};

export const getReportGroupsPage = async (
  workspaceId: string,
  offset: number,
  limit: number
) => {
  return apiService.get(`/getReportGroups`, {
    params: { workspaceId, limit, page: offset / limit },
  });
};

export const getAllReports = async (reportGroupId: string) => {
  return apiService.get(`/getReportEntries`, {
    params: { reportGroupId },
  });
};

export const getReportsPage = async (
  reportGroupId: string,
  offset: number,
  limit: number
) => {
  return apiService.get(`/getReportEntries`, {
    params: { reportGroupId, limit, page: offset / limit },
  });
};

export const createReportWithSourceGroups = async (
  sourceGroupIds: string[],
  datetime?: number[]
) => {
  return apiService.put(`/sourceGroupToReportGroup`, {
    sourceGroupIds,
    datetime,
  });
};

export const deleteReportGroup = async (reportGroupId: string) => {
  return apiService.delete(`/deleteReportGroup`, {
    data: { reportGroupIds: [reportGroupId] },
  });
};

export const getTransactions = async (
  reportId: string,
  limit: number,
  page: number
) => {
  console.log("Report Ids before callign api", reportId);
  return apiService.post(`/txs`, {
    reportIds: [reportId],
    limit: limit,
    page: page,
  });
};

export const getGraphData = async (reportId: string) => {
  return apiService.post(`/graph`, { reportIds: [reportId] });
};

export const downloadTx = async (reportId: string) => {
  return apiService.post(`/downloadReport`, {
    reportId,
    options: {
      txs_detail: true,
    },
  });
};

export const downloadReportGroup = async (reportGroupId: string) => {
  return apiService.post(`/downloadReport`, { reportGroupId });
};
