import { User } from "@/types/auth.type";
import { ReactNode, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import { ApiWebSocketCtx, CustomApiWebSocket } from "./context";

const ApiWebSocketCtxProvider = (props: { children?: ReactNode }) => {
  const socketRef = useRef<CustomApiWebSocket | null>(null);

  const user: User | null = JSON.parse(
    sessionStorage.getItem("user") || "null"
  );
  const token = user?.token;

  useEffect(() => {
    const socket = socketRef.current;

    socket?.on("connect", () => {
      console.log("::: :::: Connected to server");
    });

    socket?.on("connect_error", (err) => {
      console.error("::: ::: Connection Error:", err.message);
    });

    return () => {
      socket?.off("connect");
      socket?.off("connect_error");
    };
  }, []);

  if (!socketRef.current) {
    socketRef.current = io(import.meta.env.VITE_API_BASE_WS, {
      autoConnect: true,
      withCredentials: true,
      transports: ["websocket"],
      auth: { token },
    }) as CustomApiWebSocket;
  }
  return (
    <ApiWebSocketCtx.Provider value={{ socket: socketRef.current }}>
      {props.children}
    </ApiWebSocketCtx.Provider>
  );
};

export default ApiWebSocketCtxProvider;
