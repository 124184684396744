import {
  BLOCKCHAIN_NETWORKS,
  EXCHANGE_NETWORKS,
  SOURCE_ENTRIES_PAGE_DESCRIPTION,
  SOURCE_ENTRIES_PAGE_HEADING,
} from "@/config/general";
import {
  deleteSourceEntry,
  downloadSourceGroup,
} from "@/services/sourceService";
import {
  ArrowLeft,
  BarChart4Icon,
  Download,
  Pencil,
  Plus,
  Trash,
  Upload,
} from "lucide-react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import { ConfirmDialog } from "@/components/dialog/ConfirmDialog";
import { SourceEntryModal } from "@/components/dialog/SourceEntryModal";
import { SourceUploadModal } from "@/components/dialog/SourceUploadModal";
import AddressCell from "@/components/misc/AddressCell";
import DataTable from "@/components/table/DataTable";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { downloadCSV, getNameBySymbol } from "@/lib/utils";
import useAuthStore from "@/stores/auth.store";
import useSourcesStore from "@/stores/sources.store";
import { Source } from "@/types/sources.type";
import { IconsList } from "@/types/tables.type";
import { validateAndCleanFile } from "@/zod/source.zod";

const colsBALA = [
  {
    id: "entry.address",
    name: "Address",
    cell: (row: any) => <AddressCell fullAddress={row} />,
  },
  {
    id: "entry.network",
    name: "Network",
    width: 200,
  },
  {
    id: "entry.asset",
    name: "Asset",
    width: 200,
  },
];

const colsEXCH = [
  {
    id: "entry.network",
    name: "Exchange",
    width: 200,
  },
  {
    id: "entry.secret",
    name: "Secret",
    width: 300,
  },
  {
    id: "entry.key",
    name: "Key",
    width: 300,
  },
];

const colsPUBK = [
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
  {
    id: "entryType",
    name: "Address Type",
    width: 200,
  },
  {
    id: "entry.keys.0",
    name: "xPub Key",
    width: 300,
  },
  {
    id: "entry.keys.length",
    name: "Count",
    width: 150,
  },
];

const colsRISK = [
  {
    id: "entry.address",
    name: "Address",
    width: 650,
  },
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
];

const colsSIGN = [
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
  {
    id: "entry.signature",
    name: "Signature",
    width: 350,
  },
  {
    id: "entry.pubkey",
    name: "Public Key",
    width: 300,
  },
];

const colsMap: { [key: string]: { id: string; name: string }[] } = {
  BALA: colsBALA,
  EXCH: colsEXCH,
  PUBK: colsPUBK,
  RISK: colsRISK,
  SIGN: colsSIGN,
};

const filtersMap: {
  [key: string]: { accessorKey: string; placeholder: string };
} = {
  BALA: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  EXCH: { accessorKey: "entry.network", placeholder: "Filter Exchanges..." },
  PUBK: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
  RISK: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  SIGN: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
};

function SourceEntries() {
  const { sourceGroupId } = useParams();
  const user = useAuthStore((state) => state.user);
  const sourceEntries: Source[] = useSourcesStore((state) => state.sources);
  const [loading, clearSourceEntries] = useSourcesStore((state) => [
    state.loading,
    state.clearSourceEntries,
  ]);
  const navigate = useNavigate();

  const { toast } = useToast();

  const setSelectedSourceGroup = useSourcesStore(
    (state) => state.setSelectedSourceGroup
  );
  useEffect(() => {
    setSelectedSourceGroup(sourceGroupId || "");
    return () => {
      clearSourceEntries();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceGroupId]);

  const [sourceGroup, fetchAllSourceGroups, fetchSourcesForSelectedGroup] =
    useSourcesStore((state) => [
      state.sourceGroups.find((sg) => sg.sourceGroupId === sourceGroupId),
      state.fetchAllSourceGroups,
      state.fetchSourceEntriesForSelectedGroup,
    ]);

  const [isEditOverlayOpen, setIsEditOverlayOpen] = useState(false);
  const [deleteEntryDialogOpen, setDeleteEntryDialogOpen] = useState(false);
  const [editSourceEntryId, setEditSourceEntryId] = useState<string | null>();
  const [editSourceGroupId, setEditSourceGroupId] = useState<string | null>();

  const [isUploadOverlayOpen, setIsUploadOverlayOpen] = useState(false);

  if (!sourceGroup) return null;

  if (!user) {
    return null;
  }
  if (!sourceGroupId) {
    return null;
  }

  const cols = colsMap[sourceGroup.groupType];

  const filter = filtersMap[sourceGroup.groupType];

  const uploadComplete = async () => {
    toast({
      title: "Success",
      description: "Source uploaded successfully",
    });
    setTimeout(async () => {
      await fetchSourcesForSelectedGroup();
    }, 2000);
  };

  const downloadSource = async () => {
    if (!sourceGroupId) return;
    const response = await downloadSourceGroup(sourceGroupId);
    const cleanCSV = validateAndCleanFile(
      response.data.data,
      sourceGroup.groupType
    );
    downloadCSV(cleanCSV, `${sourceGroup.sourceName}.csv`);
  };

  const runReport = async () => {
    navigate(`/reports/run/${sourceGroupId}`);
  };

  const deleteSourceEntryFn = async () => {
    if (editSourceEntryId) {
      await deleteSourceEntry(editSourceEntryId);
    }
    await fetchAllSourceGroups();
    if (editSourceGroupId) {
      await setSelectedSourceGroup(editSourceGroupId);
      await fetchSourcesForSelectedGroup();
    }
  };

  const cellIcons: IconsList[] = (
    sourceGroup.groupType === "EXCH" ? EXCHANGE_NETWORKS : BLOCKCHAIN_NETWORKS
  ).map((network) => ({
    value: network.value,
    img: network.img,
  }));

  console.log(sourceEntries);
  return (
    <div className="">
      <ConfirmDialog
        title="Delete Entry"
        description="Are you sure you want to delete this source entry?"
        open={deleteEntryDialogOpen}
        setOpen={setDeleteEntryDialogOpen}
        severity="error"
        onConfirm={async () => {
          await deleteSourceEntryFn();
          toast({
            title: "Entry deleted",
            description: "Source entry was deleted successfully",
          });
        }}
      />
      <SourceEntryModal
        isEditDialogOpen={isEditOverlayOpen}
        setIsEditDialogOpen={setIsEditOverlayOpen}
        sourceEntryId={editSourceEntryId}
      />

      <div className="flex flex-wrap flex-col p-4 mb-2 bg-white shadow rounded-lg max-w-6xl w-full mx-auto dark:bg-gray-800">
        <div>
          <span className="text-xl font-semibold text-gray-800">
            Source: {sourceGroup.sourceName}
          </span>
          <span className="text-gray-600 block text-sm">
            ID: {sourceGroup.sourceGroupId}
          </span>
        </div>
        <div className="flex items-center space-x-4 m-2">
          <span className="text-md font-semibold">Source Type:</span>
          <span className="text-gray-700 dark:text-gray-300 block text-sm border rounded-full border-gray-300 px-4 py-2">
            {getNameBySymbol(sourceGroup.groupType)}
          </span>
        </div>

        <div className="col-span-2 pl-2 flex ">
          <Button
            className="w-full bg-primary"
            variant="outline"
            onClick={() => navigate(-1)}
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Sources
          </Button>
          <Button
            className="w-full ml-2 bg-primary"
            variant="outline"
            onClick={downloadSource}
            disabled={sourceGroup?.count === 0}
          >
            <Download className="h-4 w-4 mr-2" />
            Download CSV
          </Button>
          <Button
            className="w-full ml-2 bg-primary border"
            variant="outline"
            onClick={runReport}
            disabled={sourceGroup?.count === 0}
          >
            <BarChart4Icon className="h-4 w-4 mr-2" />
            Run Report
          </Button>
        </div>
      </div>
      <SourceEntryModal
        isEditDialogOpen={isEditOverlayOpen}
        setIsEditDialogOpen={setIsEditOverlayOpen}
        sourceEntryId={editSourceEntryId}
        sourceType={sourceGroup.groupType}
      />
      <SourceUploadModal
        isOpen={isUploadOverlayOpen}
        setIsOpen={setIsUploadOverlayOpen}
        sourceGroupId={sourceGroupId}
        onSuccess={uploadComplete}
      />
      <DashboardMainCard
        header={SOURCE_ENTRIES_PAGE_HEADING}
        description={SOURCE_ENTRIES_PAGE_DESCRIPTION}
      >
        <DataTable
          columns={cols}
          columnIcons={[
            { field: "entry.network", icons: cellIcons },
            { field: "entry.asset", icons: cellIcons },
          ]}
          data={sourceEntries}
          loading={loading}
          globalFilter={filter}
          actionButtons={[
            {
              onClick: () => setIsUploadOverlayOpen(true),
              text: "Import CSV",
              icon: Upload,
            },
            {
              onClick: () => {
                setIsEditOverlayOpen(true);
                setEditSourceEntryId(null);
              },
              text: "Add Entry",
              icon: Plus,
            },
          ]}
          rowActions={[
            {
              onClick: (rowData) => {
                setIsEditOverlayOpen(true);
                setEditSourceEntryId(rowData.sourceId);
              },
              text: "Edit",
              icon: Pencil,
            },
            {
              onClick: async (rowData) => {
                setDeleteEntryDialogOpen(true);
                setEditSourceEntryId(rowData.sourceId);
                setEditSourceGroupId(rowData.sourceGroupId);
              },
              text: "Delete",
              icon: Trash,
              variant: "danger",
            },
          ]}
        />
      </DashboardMainCard>
    </div>
  );
}

export default SourceEntries;
