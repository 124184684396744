import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import AssetLineChart from "@/components/charts/LineChartCard";
import AddressCell from "@/components/misc/AddressCell";
import NetworkAsset from "@/components/misc/NetworkAsset";
import DataTable from "@/components/table/CustomDataTable";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { useApiWebSocketCtx } from "@/context/context";
import { formatArrayLongDate, getNameBySymbol, lookupAsset } from "@/lib/utils";
import useAuthStore from "@/stores/auth.store";
import useReportsStore from "@/stores/reports.store";
import {
  AssetData,
  ChartDataItem,
  DownloadResponse,
  TransactionData,
} from "@/types/report.type";
import { ReportDownloadedPayload } from "@/types/socket.type";
import {
  ArrowDown,
  ArrowLeft,
  ArrowUp,
  Clock,
  Download,
  Loader2,
} from "lucide-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatStatus } from "./Reports";

function ReportEntry() {
  const navigate = useNavigate();
  const { reportGroupId, reportId } = useParams();
  const user = useAuthStore((state) => state.user);
  const reportEntries = useReportsStore((state) => state.reports);
  const downloadTx = useReportsStore((state) => state.downloadTx);

  const setSelectedReportGroup = useReportsStore(
    (state) => state.setSelectedReportGroup
  );
  const getTransactions = useReportsStore((state) => state.getTransactions);
  const getGraphData = useReportsStore((state) => state.getGraphData);
  const loading = useReportsStore((state) => state.loading);

  const [transactions, setTransactions] = useState<TransactionData[]>([]);
  const [graphData, setGraphData] = useState<AssetData[]>([]);
  const [downloadText, setDownloadText] = useState<string>("");
  const [reportFile, setReportFile] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100);
  const [totalPages, setTotalPages] = useState<number>(1);
  const { socket } = useApiWebSocketCtx();

  const transactionsCols = useMemo(
    () => [
      {
        id: "blocktime",
        name: "Timestamp",
        cell: (val: number) => {
          const date = new Date(val);
          return date.toLocaleString();
        },
        width: 178,
      },
      {
        id: "transaction",
        name: "Transaction ID",
        cell: (val: string) => <AddressCell fullAddress={val} />,
        width: 180,
      },
      {
        id: "before",
        name: "Balance Before",
        cell: (val: number) => {
          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 8,
          }).format(val);
        },
        width: 220,
      },
      {
        id: "after",
        name: "Balance After",
        cell: (val: number) => {
          return new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 4,
            maximumFractionDigits: 8,
          }).format(val);
        },
        width: 220,
      },
      {
        id: "change",
        name: "Change",
        cell: (val: string) => {
          const changeValue = parseFloat(val);
          const Icon =
            changeValue > 0 ? ArrowUp : changeValue < 0 ? ArrowDown : null;
          const textColor =
            changeValue > 0
              ? "text-green-600"
              : changeValue < 0
                ? "text-red-600"
                : "text-black dark:text-gray-200";

          return (
            <span className={`${textColor} font-semibold flex items-center`}>
              {Icon && <Icon className="mr-1" size={16} />}
              {Math.abs(changeValue)}
            </span>
          );
        },
        width: 230,
      },
    ],
    []
  );
  const fetchTransactions = useCallback(
    async (page: number, limit: number) => {
      const response = await getTransactions(reportId || "", limit, page);
      console.log("Response from server", response);
      if (response && response.data && response.data.length > 0) {
        setTransactions(response.data);
        setTotalPages(response.pages);
        response.limit && setPageSize(response.limit);
      }
    },
    [getTransactions, reportId]
  );

  useEffect(() => {
    const fetchGraphData = async () => {
      setSelectedReportGroup(reportGroupId || "");
      const tempGraphData: ChartDataItem[] = await getGraphData(reportId || "");
      const formattedData = tempGraphData.map((item) => ({
        datetime: item.datetime,
        after: item.after,
        asset: item.asset,
      }));
      setGraphData(formattedData);
    };
    fetchGraphData();
  }, [reportGroupId, reportId, setSelectedReportGroup, getGraphData]);

  useEffect(() => {
    const fetchData = async () => {
      await fetchTransactions(currentPage, pageSize);
    };
    fetchData();
  }, [pageSize, fetchTransactions, currentPage]);

  useEffect(() => {
    socket.on("ReportDownload", (data: ReportDownloadedPayload) => {
      setReportFile(data.downloadUrl);
    });
    return () => {
      socket.off("ReportDownload");
    };
  }, [socket]);

  useEffect(() => {
    if (reportFile) {
      const link = document.createElement("a");
      link.href = reportFile;
      link.download = reportFile;
      link.click();
    }
    setReportFile("");
    setDownloadText("");
  }, [reportFile]);

  const reportGroup = useReportsStore((state) =>
    state.reportGroups.find((sg) => sg.reportGroupId === reportGroupId)
  );

  const report = useReportsStore((state) =>
    state.reports.find((r: any) => r.reportId === reportId)
  );

  if (!reportGroup) return null;
  if (!user) return null;
  if (!reportGroup || !report) return null;

  const snapshotFirstEntryDates: number[] = reportEntries[0]?.entry?.datetime;
  const snapshotDate = formatArrayLongDate(snapshotFirstEntryDates);

  const downloadTransactions = async () => {
    setDownloadText("Generating CSV...");
    const response: DownloadResponse | string = await downloadTx(
      reportId || ""
    );
    if (
      response &&
      typeof response !== "string" &&
      response.error === false &&
      response.progress !== 100
    ) {
      setReportFile(response.data);
      setDownloadText("");
    }
  };

  // const emitReportDownload = async () => {
  //   console.log("Report emit state");
  //   socket.emit("register", {
  //     reference: reportId,
  //     type: "csv",
  //   });
  // };

  const reportGroupUrl = `/reports/${reportGroup.reportGroupId}`;
  const handlePageChange = (page: number) => {
    const validPage = !isNaN(page) ? page : 1;
    setCurrentPage(validPage);
  };

  const handlePageSizeChange = (size: number) => {
    const validSize = !isNaN(size) && size > 0 ? size : 100;
    setPageSize(validSize);
  };

  return (
    <div>
      <section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-8 lg:py-12 lg:pb-10 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-pie-chart h-4 w-4"
          >
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>{" "}
          <span className="underline-offset-4">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <BreadcrumbLink href="/">Dashboard</BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <Link to="/reports">Reports</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <Link to={reportGroupUrl}>{reportGroup.reportName}</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>
                    Report Entries: {report.entry.asset}
                  </BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </span>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            {report && (
              <NetworkAsset
                asset={lookupAsset(report.entry.asset)}
                network={lookupAsset(report.entry.network)}
              />
            )}
            <h1 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl lg:leading-[1.1]">
              Report: {reportGroup.reportName}
            </h1>
          </div>
          <Button
            className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-8 rounded-md px-3 text-xs ml-auto"
            title="Go Back to Report Entries"
            variant="outline"
            onClick={() => navigate(`/reports/${reportGroup.reportGroupId}`)}
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Entries
          </Button>
        </div>
        <div className="flex items-center bg-accent">
          <span className="ml-0 border px-2 rounded-full text-sm">
            {getNameBySymbol(reportGroup.groupType)}
          </span>
          <p className="text-md font-semibold text-gray-800 dark:text-gray-100 ml-4">
            Status:
          </p>
          <span className="ml-2">
            {formatStatus(reportGroup.status, reportGroup.message)}
          </span>

          {["BALA", "EXCH"].indexOf(reportGroup.groupType) > -1 &&
            snapshotDate && (
              <span className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs">
                <Clock className="h-4 w-4 m-2" />
                {snapshotDate}
              </span>
            )}
        </div>
        <div className="flex w-full items-center justify-start gap-2 py-2">
          {transactions && transactions.length > 0 && (
            <Button
              title="Download Report"
              variant="outline"
              onClick={() => downloadTransactions}
            >
              {downloadText ? (
                <Loader2 className="h-4 w-4 mr-2" />
              ) : (
                <Download className="h-4 w-4 mr-2" />
              )}
              {downloadText || "Download Report"}
            </Button>
          )}
        </div>
      </section>

      {graphData && graphData.length > 0 && (
        <DashboardMainCard
          header={`Asset: ${report.entry.asset}`}
          description={report.entry.address}
        >
          <AssetLineChart data={graphData} />
        </DashboardMainCard>
      )}
      <DashboardMainCard
        header={"Transactions"}
        description={"Report data entry transactions"}
      >
        {transactions.length > 0 ? (
          <DataTable
            columns={transactionsCols}
            data={transactions}
            loading={loading}
            pagination={{
              currentPage,
              pageSize,
              totalPages,
              onPageChange: handlePageChange,
              onPageSizeChange: handlePageSizeChange,
            }}
            actionButtons={
              transactions && transactions.length > 0
                ? [
                    {
                      onClick: downloadTransactions,
                      text: downloadText || "Download Report",
                      icon: downloadText ? Loader2 : Download,
                    },
                  ]
                : []
            }
          />
        ) : (
          <p>No transactions available.</p>
        )}
      </DashboardMainCard>
    </div>
  );
}

export default ReportEntry;
