import { SOURCE_PAGE_DESCRIPTION, SOURCE_PAGE_HEADING } from "@/config/general";
import { BarChart, Cog, FileDown, Pencil, Upload } from "lucide-react";

import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import { SourceModal } from "@/components/dialog/SourcesModal";
import DataTable from "@/components/table/DataTable";
import { downloadCSV, getNameBySymbol } from "@/lib/utils";
import { downloadSourceGroup } from "@/services/sourceService";
import useAuthStore from "@/stores/auth.store";
import useSourcesStore from "@/stores/sources.store";
import Papa from "papaparse";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Sources() {
  const user = useAuthStore((state) => state.user);
  const navigate = useNavigate();
  const sourceGroups = useSourcesStore((state) => state.sourceGroups);
  const sourceLoading = useSourcesStore((state) => state.loading);
  const [sourceOverlayIsOpen, setSourceOverlayIsOpen] = useState(false);
  const [editSourceId, setEditSourceId] = useState<string | null>(null);

  if (!user) {
    return null;
  }

  const cols = [
    { id: "sourceName", name: "Name", width: 200 },
    {
      id: "groupType",
      name: "Type",
      width: 180,
      cell: (value: string) => getNameBySymbol(value),
    },
    { id: "count", name: "Entries", width: 180 },
  ];

  const validateAndCleanCSV = (csvData: string): string => {
    let cleanedData: any[] = [];
    // TODO - Add more validation for different source types
    const requiredColumns: string[] = ["ADDRESS", "NETWORK", "ASSET"];

    // Please remove empty rows from the CSV
    Papa.parse(csvData, {
      header: true,
      complete: (results) => {
        cleanedData = results.data
          .map((row: any) => {
            const cleanedRow: any = {};
            requiredColumns.forEach((col) => {
              cleanedRow[col] = row[col] || ""; // Keep only required columns
            });
            return cleanedRow;
          })
          .filter((row: any) => row.ADDRESS !== "");
      },
    });

    // Convert the cleaned data back to CSV
    return Papa.unparse(cleanedData);
  };

  return (
    <>
      <SourceModal
        isEditDialogOpen={sourceOverlayIsOpen}
        setIsEditDialogOpen={setSourceOverlayIsOpen}
        sourceId={editSourceId}
      />

      <DashboardMainCard
        header={SOURCE_PAGE_HEADING}
        description={SOURCE_PAGE_DESCRIPTION}
      >
        <DataTable
          columns={cols}
          data={sourceGroups}
          loading={sourceLoading}
          globalFilter={{
            placeholder: "Filter Source Names...",
            accessorKey: "sourceName",
          }}
          actionButtons={[
            {
              onClick: () => {
                setEditSourceId(null);
                setSourceOverlayIsOpen(true);
              },
              text: "New Source",
              icon: Upload,
            },
          ]}
          rowActions={[
            {
              text: "Manage",
              icon: Cog,
              onClick: (rowData) => {
                navigate(`/sources/${rowData.sourceGroupId}`);
              },
            },
            {
              text: "Rename",
              icon: Pencil,
              onClick: (rowData) => {
                setEditSourceId(rowData.sourceGroupId);
                setSourceOverlayIsOpen(true);
              },
            },
            {
              text: "Download",
              disabled: (rowData) => rowData?.count === 0,
              icon: FileDown,
              onClick: async (rowData) => {
                const response = await downloadSourceGroup(
                  rowData.sourceGroupId
                );
                const cleanCSV = validateAndCleanCSV(response.data.data);
                downloadCSV(cleanCSV, `${rowData.sourceName}.csv`);
              },
            },
            {
              text: "Run Report",
              // @ts-ignore
              disabled: (rowData) => rowData?.count === 0,
              icon: BarChart,
              onClick: (rowData) => {
                navigate(`/reports/run/${rowData.sourceGroupId}`);
              },
            },
          ]}
        />
      </DashboardMainCard>
    </>
  );
}

export default Sources;
