import { DashboardMainCard } from "@/components/cards/DashboardMainCard";
import AddressCell from "@/components/misc/AddressCell";
import DataTable from "@/components/table/DataTable";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { Button } from "@/components/ui/button";
import { Progress } from "@/components/ui/progress";
import { BLOCKCHAIN_NETWORKS, EXCHANGE_NETWORKS } from "@/config/general";
import {
  downloadCSV,
  formatArrayDate,
  formatBalance,
  getNameBySymbol,
} from "@/lib/utils";
import { downloadReportGroup } from "@/services/reportService";
import useAuthStore from "@/stores/auth.store";
import useReportsStore from "@/stores/reports.store";
import { IconsList } from "@/types/tables.type";
import { ArrowLeft, Clock, Download, Eye } from "lucide-react";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { formatStatus } from "./Reports";

const colsBALA = [
  {
    id: "entry.address",
    name: "Address",
    width: 165,
    cell: (row: any) => <AddressCell fullAddress={row} />,
  },
  {
    id: "entry.network",
    name: "Network",
    width: 10,
  },
  {
    id: "entry.asset",
    name: "Asset",
    width: 10,
  },
  {
    id: "progress",
    name: "Progress",
    cell: (row: any) => {
      const roundedProgress = Math.round(row);
      return (
        <div className="w-1/2 text-center">
          <Progress value={roundedProgress} />
          {roundedProgress} %
        </div>
      );
    },
    width: 10,
  },
  {
    id: "status",
    name: "Status",
    width: 10,
    cell: (row: string) => formatStatus(row),
  },
  {
    id: "result",
    name: "Asset Balance",
    width: 80,
    cell: (row: any) => {
      if (Array.isArray(row) && row.length > 0) {
        const balance = row.reduce((acc: number, item: any) => {
          return acc + (item.balance || 0);
        }, 0);
        const formattedBalance = formatBalance(balance);
        const assetName = row[0].asset || "";
        return `${formattedBalance} ${assetName}`;
      } else {
        return `0.00`;
      }
    },
  },
  {
    id: "result",
    name: "Balance Value",
    width: 80,
    cell: (row: any) => {
      if (Array.isArray(row) && row.length > 0) {
        const total = row.reduce((acc: number, item: any) => {
          if (item.price && item.balance) {
            return acc + item.price * item.balance;
          }
          return acc;
        }, 0);
        const formattedTotal = formatBalance(total);
        const currency = row[0].currency || "";
        return `${formattedTotal} ${currency}`;
      } else {
        return `0.00`;
      }
    },
  },
];

const colsEXCH = [
  {
    id: "entry.network",
    name: "Network",
  },
];

const colsPUBK = [
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
  {
    id: "Result.value",
    name: "Status",
    width: 200,
  },
];

const colsRISK = [
  {
    id: "entry.address",
    name: "Address",
    width: 600,
  },
  {
    id: "entry.network",
    name: "Network",
    width: 150,
  },
  {
    id: "Result.sanctioned",
    name: "Sanctioned",
    width: 200,
  },
  {
    id: "Result.score",
    name: "Score",
    width: 150,
  },
];

const colsSIGN = [
  {
    id: "entry.network",
    name: "Network",
    width: 200,
  },
  {
    id: "entry.message",
    name: "Message",
    width: 600,
  },
  {
    id: "Result.valid",
    name: "Valid",
    width: 200,
  },
];

const colsMap: { [key: string]: { id: string; name: string }[] } = {
  BALA: colsBALA,
  EXCH: colsEXCH,
  PUBK: colsPUBK,
  RISK: colsRISK,
  SIGN: colsSIGN,
};

const filtersMap: {
  [key: string]: { accessorKey: string; placeholder: string };
} = {
  BALA: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  EXCH: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
  PUBK: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
  RISK: { accessorKey: "entry.address", placeholder: "Filter Addresses..." },
  SIGN: { accessorKey: "entry.network", placeholder: "Filter Networks..." },
};

function ReportEntries() {
  const navigate = useNavigate();

  const { reportGroupId } = useParams();
  const user = useAuthStore((state) => state.user);
  const reportEntries = useReportsStore((state) => state.reports);
  const loading = useReportsStore((state) => state.loading);

  const setSelectedReportGroup = useReportsStore(
    (state) => state.setSelectedReportGroup
  );

  useEffect(() => {
    if (reportGroupId) {
      setSelectedReportGroup(reportGroupId || "");
    }
  }, [reportGroupId, setSelectedReportGroup]);

  const reportGroup = useReportsStore((state) =>
    state.reportGroups.find((sg) => sg.reportGroupId === reportGroupId)
  );

  if (!reportGroup) return null;

  if (!user) {
    return null;
  }

  const cols = colsMap[reportGroup.groupType];
  const filter = filtersMap[reportGroup.groupType];

  const cellIcons: IconsList[] = (
    reportGroup.groupType === "EXCH" ? EXCHANGE_NETWORKS : BLOCKCHAIN_NETWORKS
  ).map((network) => ({
    value: network.value,
    img: network.img,
  }));

  const handleDownload = async (row: any) => {
    const response = await downloadReportGroup(row.ReportID);
    downloadCSV(response.data.data, `${row.reportName}.csv`);
  };

  const getRowActions = (groupType: string) => {
    const baseActions = [];

    const isReportSuccess = reportGroup.status === "S";

    if (["BALA"].includes(groupType)) {
      baseActions.push({
        text: "View",
        onClick: (row: any) =>
          row.status !== "P" &&
          row.status !== "F" &&
          navigate(`/reports/${reportGroupId}/${row.reportId}`),
        icon: Eye,
        disabled: (row: any) => row.status === "P" || row.status === "F",
      });
    }

    if (groupType === "PUBK" && isReportSuccess) {
      baseActions.push({
        text: "Download",
        onClick: (row: any) => {
          handleDownload(row);
        },
        icon: Download,
      });
    }

    if (groupType === "EXCH" && isReportSuccess) {
      baseActions.push({
        text: "Download",
        onClick: async (row: any) => {
          handleDownload(row);
        },
        icon: Download,
      });
    }

    return baseActions;
  };

  //TODO:Fix the type returned the action creator
  //@ts-ignore-error
  const snapshotFirstEntryDates: number[] = reportEntries[0]?.entry?.datetime;
  const snapshotDate = formatArrayDate(snapshotFirstEntryDates);
  return (
    <div>
      <section className="mx-auto flex flex-col items-start gap-2 py-2 md:py-2 md:pb-8 lg:py-12 lg:pb-10 max-w-6xl">
        <div className="group inline-flex items-center px-0.1 text-sm font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            className="lucide lucide-pie-chart h-4 w-4"
          >
            <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
            <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
          </svg>
          <div
            data-orientation="vertical"
            role="none"
            className="shrink-0 bg-border w-[1px] mx-2 h-4"
          ></div>{" "}
          <span className="underline-offset-4">
            <Breadcrumb>
              <BreadcrumbList>
                <BreadcrumbItem>
                  <Link to="/dashboard">Dashboard</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <Link to="/reports">Reports</Link>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>{reportGroup.reportName}</BreadcrumbPage>
                </BreadcrumbItem>
              </BreadcrumbList>
            </Breadcrumb>
          </span>
        </div>
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center space-x-4">
            <h1 className="text-3xl font-bold leading-tight tracking-tighter md:text-4xl lg:leading-[1.1]">
              Report: {reportGroup.reportName}
            </h1>
          </div>
          <Button
            className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground shadow hover:bg-primary/90 h-8 rounded-md px-3 text-xs ml-auto"
            title="Go Back to Report Entries"
            variant="outline"
            onClick={() => navigate("/reports")}
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Back to Reports
          </Button>
        </div>
        <div className="flex items-center bg-accent">
          <span className="ml-0 border px-2 rounded-full text-sm">
            {getNameBySymbol(reportGroup.groupType)}
          </span>
          <p className="text-md font-semibold text-gray-800 dark:text-gray-100 ml-4">
            Status:
          </p>
          <span className="ml-2">
            {formatStatus(reportGroup.status, reportGroup.message)}
          </span>

          {["BALA", "EXCH"].indexOf(reportGroup.groupType) > -1 &&
            snapshotDate && (
              <span className="inline-flex items-center justify-center whitespace-nowrap font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-8 rounded-md px-3 text-xs">
                <Clock className="h-4 w-4 m-2" />
                {snapshotDate}
              </span>
            )}
        </div>
        {["R", "P"].includes(reportGroup.status) && (
          <div>
            <p className="text-xl font-semibold text-gray-800 my-2">
              Progress:
            </p>
            <div className="text-center">
              <Progress value={Number(reportGroup.progress)} />
              {reportGroup.progress.toString()} %
            </div>
          </div>
        )}
      </section>
      <DashboardMainCard
        header={"Report Entries"}
        description={"Explore Report data entries"}
      >
        <DataTable
          columns={cols}
          columnIcons={[
            { field: "entry.network", icons: cellIcons },
            { field: "entry.asset", icons: cellIcons },
          ]}
          data={reportEntries}
          loading={loading}
          globalFilter={filter}
          actionButtons={[]}
          rowActions={getRowActions(reportGroup.groupType)}
        />
      </DashboardMainCard>
    </div>
  );
}

export default ReportEntries;
